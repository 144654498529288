<script src="../../../../../../../../Users/Legion/Downloads/translations/en.js"></script>
<template>
  <div class="book-appointment-container">
    <vs-popup title="" :active.sync="showLoginModal">
      <h4>Login</h4>
      <div class="vx-row">
        <login-firebase></login-firebase>
      </div>
    </vs-popup>
    <div class="organization-details">
      <h1>{{$t('book.packageDescription')}}</h1>
    </div>
    <div class="appointment-progressbar-container">
      <ul class="progress-steps">
        <li class="progress-step">
          <div class="progress-step-title">
            {{$t('book.selectDate')}}
          </div>
        </li>
        <li class="progress-step">
          <div class="progress-step-title">
            {{$t('book.fillInformation')}}
          </div>
        </li>
        <li class="progress-step">
          <div class="progress-step-title">
            {{$t('book.confirmation')}}
          </div>
        </li>
      </ul>
    </div>

    <div class="appointment-user-details-container">
      <form-wizard
        v-if="!showEndOfBooking"
        ref="checkoutWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        :hide-buttons="true">
        <div slot="title"></div>
        <tab-content>
          <form data-vv-scope="patient-general-info" class="patient-general-info-form patient-form">
          <div class="vx-row">
            <div class="vx-col lg:w-3/3 w-full">
              <vx-card class="mb-base">
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full">
                      <h5>{{$t('patientType')}}</h5>
                      <div class="demo-alignment mb-5">
                        <vs-radio v-model="patientType" vs-value="existing">{{$t('existing')}}</vs-radio>
                        <vs-radio v-model="patientType" vs-value="new">{{$t('new')}}</vs-radio>
                      </div>
                    </div>
                  </div>
                <hr>
                  <div v-if="patientType === 'new'">
                    <div class="vx-row mt-4">
                      <div class="vx-col sm:w-1/2 w-full">
                        <h5>{{$t('gender')}}</h5>
                        <div class="demo-alignment mb-5">
                          <vs-radio v-model="gender" vs-value="Female">{{$t('female')}}</vs-radio>
                          <vs-radio v-model="gender" vs-value="Male">{{$t('male')}}</vs-radio>
                        </div>
                      </div>
                      <div class="vx-col sm:w-1/2 w-full"></div>
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <div class="vs-component vs-con-input-label vs-input w-full mt-5 vs-input-primary">
                          <label for="firstName" class="vs-input--label">{{$t('firstName')}}</label>
                          <div class="vs-con-input greeting-container">
                            <select v-model="greeting">
                              <option>Mr.</option>
                              <option>Ms.</option>
                            </select>
                            <input v-validate="'required'" v-model="firstName" type="text" data-vv-as="field" id="firstName" name="firstName" class="vs-inputx vs-input--input normal" style="border: 1px solid rgba(0, 0, 0, 0.2);">
                          </div>
                        </div>
                        <span
                          v-show="errors.has('patient-general-info.firstName')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.firstName') }}</span>
                      </div>
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <vs-input
                          v-validate="'required|alpha_spaces'"
                          data-vv-as="field"
                          name="lastName"
                          :label="$t('lastName')"
                          v-model="lastName"
                          class="w-full mt-5"
                        />
                        <span
                          v-show="errors.has('patient-general-info.lastName')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.lastName') }}</span>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <div class="vs-component vs-con-input-label vs-input w-full mt-5 vs-input-primary">
                          <label for="birthdate">{{$t('birthDate')}}</label>
                          <datepicker
                            v-model="birthdate"
                            id="birthdate"
                            format="dd/MM/yyyy"
                            placeholder="DD/MM/YYYY"
                          ></datepicker>
                          <input type="hidden" name="birthdate" v-validate="'required'" v-model="birthdate">
                        </div>
                        <span
                          v-show="errors.has('patient-general-info.birthdate')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.birthdate') }}</span>
                      </div>
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <div class="vs-component vs-con-input-label vs-input w-full mt-5 vs-input-primary">
                          <label for="mobileNum">{{$t('mobileNumber')}}</label>
                          <vue-tel-input v-model="mobileNum"></vue-tel-input>
                          <input type="hidden" name="mobileNum" v-validate="'required'" v-model="mobileNum">
                        </div>
                        <span
                          v-show="errors.has('patient-general-info.mobileNum')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.mobileNum') }}</span>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <vs-input
                          v-validate="'required|email'"
                          data-vv-as="field"
                          name="email"
                          :label="$t('email')"
                          v-model="email"
                          class="w-full mt-5"
                        />
                        <span
                          v-show="errors.has('patient-general-info.email')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.email') }}</span>
                      </div>
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <vs-input
                          v-validate="'required'"
                          data-vv-as="field"
                          name="address"
                          :label="$t('address')"
                          v-model="address"
                          class="w-full mt-5"
                        />
                        <span
                          v-show="errors.has('patient-general-info.address')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.address') }}</span>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <vs-input
                          v-validate="'required'"
                          name="postalCode"
                          :label="$t('postalCode')"
                          v-model="postalCode"
                          class="w-full mt-5"
                        />
                        <span
                          v-show="errors.has('patient-general-info.postalCode')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.postalCode') }}</span>
                      </div>
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <vs-input
                          v-validate="'required'"
                          name="city"
                          :label="$t('city')"
                          v-model="city"
                          class="w-full mt-5"
                        />
                        <span
                          v-show="errors.has('patient-general-info.city')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.city') }}</span>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <vs-input
                          v-validate="'required'"
                          name="nationality"
                          :label="$t('nationality')"
                          v-model="nationality"
                          class="w-full mt-5"
                        />
                        <span
                          v-show="errors.has('patient-general-info.nationality')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.nationality') }}</span>
                      </div>
                      <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <vs-input
                          v-validate="'required'"
                          name="job"
                          :label="$t('job')"
                          v-model="job"
                          class="w-full mt-5"
                        />
                        <span
                          v-show="errors.has('patient-general-info.job')"
                          class="text-danger"
                        >{{ errors.first('patient-general-info.job') }}</span>
                      </div>
                    </div>
                    <!--                  <vs-button class="mt-6 ml-auto flex" @click.prevent="goToIdentityStep">Next</vs-button>-->
                    <vs-button class="mt-6 ml-auto flex" @click.prevent="goToIdentityStep">{{$t('submit')}}</vs-button>
                  </div>
                <div v-else>
                  <div class="px-8 pt-8 login-tabs-container">

                    <div class="vx-card__title mb-4">
                      <h4 class="mb-4">Please login with your existing profile credentials</h4>
                    </div>
                    <div style="display: flex;justify-content: center">
                      <div class="login-firebase-container existing-account-container">
                        <vs-input
                          v-validate="'required|email|min:3'"
                          data-vv-validate-on="blur"
                          id="username"
                          name="username"
                          :label="$t('username')"
                          v-model="form.username"
                          class="w-full mt-5"/>
                        <span class="text-danger text-sm">{{ errors.first('username') }}</span>

                        <vs-input
                          data-vv-validate-on="blur"
                          v-validate="'required|min:6|max:10'"
                          type="password"
                          id="password"
                          name="password"
                          :label="$t('password')"
                          v-model="form.password"
                          class="w-full mt-5" />
                        <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                        <div class="flex flex-wrap justify-between my-5">
                          <!--                    <vs-checkbox v-model="checkbox_remember_me" class="mb-3" id="checkbox_remember_me">Remember Me</vs-checkbox>-->
                          <!--                    <router-link to="/pages/forgot-password">Forgot Password?</router-link>-->
                        </div>
<!--                        <vs-button class="float-left" @click="registerUser" id="registerUser">Register</vs-button>-->
                        <vs-button class="float-right" :disabled="!validateForm" @click="login" id="loginFirebase">{{$t('login')}}</vs-button>

                      </div>
                    </div>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
          </form>
        </tab-content>
        <tab-content>
          <form data-vv-scope="patient-identity-info" class="patient-identity-info-form patient-form">
          <div class="vx-row">
            <div class="vx-col lg:w-3/3 w-full">
              <vx-card class="mb-base">
                <div class="vx-row">
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <label for="expectedDeliveryDate">Expected Delivery Date</label>
                    <datepicker class="expected-delivery-date" placeholder="Select Date"
                                format="dd/MM/yyyy"
                                v-model="expectedDeliveryDate"
                                :disabled-dates="disabledDates"
                                id="expectedDeliveryDate">
                    </datepicker>
                    <input type="hidden" name="expectedDeliveryDate" v-validate="'required'" v-model="expectedDeliveryDate">
                    <span
                      v-show="errors.has('patient-identity-info.expectedDeliveryDate')"
                      class="text-danger"
                    >{{ errors.first('patient-identity-info.expectedDeliveryDate') }}
                      </span>
                  </div>
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <label for="roomType">Room Type</label>
                    <v-select
                      placeholder="Select a room type"
                      v-model="roomType"
                      id="roomType"
                      name="roomType"
                      v-validate="'required'"
                      :options="roomTypes"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="mb-4 md:mb-0"
                    />
                    <span
                      v-show="errors.has('patient-identity-info.roomType')"
                      class="text-danger"
                    >{{ errors.first('patient-identity-info.roomType') }}</span>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full appointment-room-book-calendar">
                    <div class="calendar-wrapper">
                      <div class="calendar-header">
                        <div class="calendar-header-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 77 78">
                            <image width="77" height="78" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABOCAYAAABlnZseAAADv0lEQVR4nO2cT6hVRRjAf+9mTkWWoZS5KDMilMhUopCkEolazCKSEmZR5ELo305o4SIRF1Iro00QITjLQJpsZyBaEAWFQRBp/wQXRaSF4Vj5YmSu3Ddv7uX5nfPuu8/7/VZvzpn3nfl+98w5c85795uYnJykKSb4JcBDwLfRup8aB2wZE/wKYDXwRbTut6bRO00DmOAfBn4ADgEnTPAvzkLeYkzwLwDf5/GdNMFvaBqzsTTgDeCm/PM1wFstxGyTN4EFOd4iYFfT2G1Iu61o39JCzDZZWsQq21dMG9LGDpUmQKUJqC45TPBrgOeBjcCdwLUDQi/KN4BezoxGepdYXLT/A/4a0P8f4BfgGLA/WvdV2WGKNBN8uki+Azzb5qjnOR8AL0Xrfp0mzQR/N3A4n1nKVNKZtylad/KyNBN8Wmd9CdyjsvqSFvDro3Vnuou+PQOE/Q2kR49+z1vLgYXFtlF6lFpRtC8Apwf0vxW4obJ9JbAX2D6x8MMDy4CfK4l/A7wKHI3WXex3BBN8ulA+0LstWjcxo3SGgAm+/LC/jtatHZBPGvsjwNvAmmL3v+lDSGfa0xVhae5ujNadHZXkh0W0Lkk+aoJ/FPgcuLfn0MnXM538dqJk9zgK6yXnX3tOfTBJu72y49PhDW+k+awyuOWdytQkz10FarOt08ZjVN+bxIhQ3ggav3VtQ9qRol07peeSY8Wxy/YVs6CFZHbmKf5EXqa8NmLStgL7gPuBT4DXmwZsLC1alxa/rzSNM1tE69JCdkub4fXVkACVJkClCVBpAlSagH53z0Mm+AsjPfLhUL7Gv0Q/aavnebKzik5PASpNgEoT0O+adtco/svUsDHBp7+Z/lEeVs80ASpNgEoToNIEqDQBKk2AShOg0gSoNAEqTYBKE6DSBKg0ASpNgEoToNIEqDQBKk2AShOg0gSoNAEqTYBKE6DSBKg0ASpNgEoToNIEqDQBKk2AShOg0gSoNAEqTYBKE6DSBHTmwdep55Lrasfu5OouJavGwcgMuK/S5fck7Xhlx45cCWVsyfnvqOR/PEk7WNnxOPC+Cf7mcZRmgk814d7L38svOditavUx8FSlw5+5+sH54Q57TknXsQ09FVV7ORyt29z9xkqqcJAKLd1YdEq/+OTV72lGnANeprvkiNadAJ7LZbKU6SQvW6N139G7TovWpSn6GPCjSptCqtK3OVr3UXfjtEKaJvjrgW25kOa6MV0AX8yriv3Au7n2yGUGFjw3wadr3B19ijhdraSpeCpaV69QCvwPI1vL6vWR3VgAAAAASUVORK5CYII="/>
                          </svg>
                        </div>
                        <h2 class="calendar-header-title">
                          Choose date
                        </h2>
                      </div>
                      <datepicker v-model="roomBookDate" :inline="true" :disabled-dates="disabledDates">
                      </datepicker>
                    </div>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <label for="doctor">Doctor in the clinic following you</label>
                    <v-select
                      placeholder="Select a doctor"
                      v-model="doctor"
                      id="doctor"
                      name="doctor"
                      v-validate="'required'"
                      :options="doctorsList"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="mb-4 md:mb-0"
                    />
                    <span
                      v-show="errors.has('patient-identity-info.doctor')"
                      class="text-danger"
                    >{{ errors.first('patient-identity-info.doctor') }}</span>
                    <div class="doctor-chosen-container">
                      <vs-checkbox v-model="doctorNotSelected" vs-value="1">I have not yet chosen a doctor</vs-checkbox>
                    </div>
                  </div>
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <label for="identityFile">Upload a photocopy of your identity (in PDF, JPG or PNG format)</label>
                    <div class="file-input-wrapper">
                      <input id="identityFile" type="file" class="inputfile" name="identityFile" v-validate="'required|ext:jpg,pdf,png'" @change="handleFileChange($event)">
                      <label for="identityFile">
                        <span id="file-name" class="file-box">
                          {{ fileName }}
                        </span>
                        <span class="file-button">
                          <span class="file-button-content">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 93 100">
                            <g>
                              <image x="21" width="50" height="75" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABLCAYAAAAyEtS4AAACPUlEQVRoge2bP0scYRCHnwskXZIqhWCRwkICEtKmUVAkjZ1NGkXIF7DWwnyFNIEUqQQRCxujSaNCuuC/FH6AdDaSIpUSsmFglVPPvd15572bg3ngOLhjb+a5nXd/3N5uqygKMtICXgHPgB/A71ylHmSUeArsAofAV+AXMJurWK498gj4Akzdev0SeAPsWRfMsUdknD51kKAU3AReWBfNIbICzFe8LyO3AwyZVpXRMnwsFPU5KorisVVtyzUyDWwBDxtsIweBGeBvanGr0XoJbDSUoFz4Hy0asBAZLo9QT5TbvwOWUptIHS1ZuN+BscQ+pIk5YFX7ASki92WFlqSM0Y5WVVZoScoYrUi3rNCizhjNaC0AnzNItHMMjAN/6m7QVESTFVoaZUyT0dJmhZZGGVNXJDUrtNTOmDqjZZUVWmplTDcR66zQ0jVjqkYrR1Zo6ZoxVSK5skJLZcbcN1q9yAotHTOmk0gvs0LLnYy5PVq9zgotdzKmXaRfWaHlRsZcjVa/s0LLdcaISKucuekBk7hCMmZCROQIsO+jJzXfZI08H9Dm2xkRkZ9++lFzICInwIcBFRDOgOX2QJwEXit+/q4YNSTrtOlaPQfW5NniTKPVqUr5Qt5rN875/0hPCRFvhIg3QsQbIeKNEPFGiHgjRLwRIt4IEW+EiDdCxBsh4o0Q8UaIeCNEvBEi3ggRb4SIN0IkA//6LXJq5JR0KYmFyCJwkfgZ2+Xlh2qs7rEaBd4qrr6T4nLD5XrSfVbAf9+xKHuOJV2jAAAAAElFTkSuQmCC"/>
                            </g>
                            <g>
                              <image y="68" width="93" height="32" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF0AAAAgCAYAAABwzXTcAAABD0lEQVRoge3ZoVICURiG4VcwmejOcA0UM92r4BIMjtdgoBhoJIJmsoEroBicwaEQKBQDhWFclvmDMyiFA8I3eL4n756z/7tl5+xFWZYd4IrdPQOvCdf/d6n9uhH9E6gl3HQHPOVeekNqv1bl6I9kWxxdwNEFHF3A0QUcXcDRBRxdwNEFHF3A0QUcXcDRBRxdwNEFHF3gco8tr4HG2U/+d6qpK+3z58gO4z9HCo4u4OgCji7g6AIRfZHd1FpFRH/PuYDAKKK/ZDe2zhgYRvQe8JZrhRMqgXtgFdGXwC3wkc34pxfBH4A+G18vU+AGeARmGcU4tgIYAE2g/b1XnL383jdeRN3nMQf7AibA/MdKwBpkVyqqMHsf1QAAAABJRU5ErkJggg=="/>
                            </g>
                          </svg>
                          <span class="file-button-title">
                            To choose
                          </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <span
                      v-show="errors.has('patient-identity-info.identityFile')"
                      class="text-danger"
                    >{{ errors.first('patient-identity-info.identityFile') }}</span>
                  </div>
                </div>
                <vs-button class="mt-6 ml-auto flex" @click.prevent="goToContactPersonStep">Next</vs-button>
              </vx-card>
            </div>
          </div>
          </form>
        </tab-content>
        <tab-content>
          <form data-vv-scope="patient-contact-person-info" class="patient-contact-person-info-form patient-form">
          <div class="vx-row">
            <div class="vx-col lg:w-3/3 w-full">
              <vx-card class="mb-base">
                <div class="person-notify">
                  <span class="person-notify-title">Reliable Person </span>(person of trust is the person designated by the patient to accompany, to support or to know his well)
                </div>
                <div class="vx-row">
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <div class="vs-component vs-con-input-label vs-input w-full mt-5 vs-input-primary">
                      <label for="firstName" class="vs-input--label">Name</label>
                      <div class="vs-con-input greeting-container">
                        <select v-model="reliablePersonGreeting">
                          <option>Mr.</option>
                          <option>Ms.</option>
                        </select>
                        <input v-validate="'required'" v-model="reliablePersonName" type="text" data-vv-as="field" id="reliablePersonName" name="reliablePersonName" class="vs-inputx vs-input--input normal" style="border: 1px solid rgba(0, 0, 0, 0.2);">
                      </div>
                    </div>
                    <span
                      v-show="errors.has('patient-contact-person-info.reliablePersonName')"
                      class="text-danger"
                    >{{ errors.first('patient-contact-person-info.reliablePersonName') }}</span>
                  </div>
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <vs-input
                      v-validate="'required'"
                      name="reliablePersonRelationship"
                      label="Relationship"
                      v-model="reliablePersonRelationship"
                      class="w-full mt-5"
                    />
                    <span
                      v-show="errors.has('patient-contact-person-info.reliablePersonRelationship')"
                      class="text-danger"
                    >{{ errors.first('patient-contact-person-info.reliablePersonRelationship') }}</span>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <div class="vs-component vs-con-input-label vs-input w-full mt-5 vs-input-primary">
                      <label for="reliablePersonPhoneNumber">Phone Number</label>
                      <vue-tel-input v-model="reliablePersonPhoneNumber"></vue-tel-input>
                      <input type="hidden" name="reliablePersonPhoneNumber" v-validate="'required'" v-model="reliablePersonPhoneNumber">
                    </div>
                    <span
                      v-show="errors.has('patient-contact-person-info.reliablePersonPhoneNumber')"
                      class="text-danger"
                    >{{ errors.first('patient-contact-person-info.reliablePersonPhoneNumber') }}</span>
                  </div>
                </div>
                <div class="person-notify warned-person">
                  <span class="person-notify-title">Person To Be Warned </span>(The person to be warned is the person who will be alerted if your state of health worsens)
                </div>
                <div class="vx-row">
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <div class="vs-component vs-con-input-label vs-input w-full mt-5 vs-input-primary">
                      <label for="firstName" class="vs-input--label">Name</label>
                      <div class="vs-con-input greeting-container">
                        <select v-model="warnedPersonGreeting">
                          <option>Mr.</option>
                          <option>Ms.</option>
                        </select>
                        <input v-validate="'required'" v-model="warnedPersonName" type="text" data-vv-as="field" id="warnedPersonName" name="warnedPersonName" class="vs-inputx vs-input--input normal" style="border: 1px solid rgba(0, 0, 0, 0.2);">
                      </div>
                    </div>
                    <span
                      v-show="errors.has('patient-contact-person-info.warnedPersonName')"
                      class="text-danger"
                    >{{ errors.first('patient-contact-person-info.warnedPersonName') }}</span>
                  </div>
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <vs-input
                      v-validate="'required'"
                      name="warnedPersonLink"
                      label="Link with the person"
                      v-model="warnedPersonLink"
                      class="w-full mt-5"
                    />
                    <span
                      v-show="errors.has('patient-contact-person-info.warnedPersonLink')"
                      class="text-danger"
                    >{{ errors.first('patient-contact-person-info.warnedPersonLink') }}</span>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col sm:w-full xs:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                    <div class="vs-component vs-con-input-label vs-input w-full mt-5 vs-input-primary">
                      <label for="warnedPersonPhoneNumber">Phone Number</label>
                      <vue-tel-input v-model="warnedPersonPhoneNumber"></vue-tel-input>
                      <input type="hidden" name="warnedPersonPhoneNumber" v-validate="'required'" v-model="warnedPersonPhoneNumber">
                    </div>
                    <span
                      v-show="errors.has('patient-contact-person-info.warnedPersonPhoneNumber')"
                      class="text-danger"
                    >{{ errors.first('patient-contact-person-info.warnedPersonPhoneNumber') }}</span>
                  </div>
                </div>
                <h3 class="general-conditions-title">General Conditions and Approval</h3>
                <p class="general-conditions-description">
                  "I have been informed by the Natecia Lyon Private Hospital of the cost of each of the services relating to my stay in the establishment, and accept all the pricing conditions - Do not hesitate to have your doctor's fees quoted"
                </p>
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="getRecaptchaKey"
                  @expired="onCaptchaExpired"
                  @verify="verifyRecaptcha"
                />
                <span
                  v-if="recaptchaRequiredMessage"
                  class="text-danger"
                >{{ recaptchaRequiredMessage }}
                </span>
                <vs-button class="mt-6 ml-auto flex" @click.prevent="goToSummaryStep">Next</vs-button>
              </vx-card>
            </div>
          </div>
          </form>
        </tab-content>
        <tab-content>
          <div class="vx-row">
            <div class="vx-col lg:w-3/3 w-full">
              <vx-card class="mb-base">
                <div class="confirm-booking-container">
                  <div class="confirm-booking-header">
                    <div class="confirm-booking-img">
                      <img :src="organizationDetails.custom_illustration_step_3" alt="Thank you for your booking!">
                    </div>
                    <h3>Thank you for your booking!</h3>
                  </div>
                  <div class="confirm-booking-content">
                    <div class="confirm-booking-notification">
                      Please check the details below for your upcoming appointment:
                    </div>
                    <div class="confirm-booking-patient-info">
                      Name: {{ firstName }} {{ lastName }}<br/>
                      Date of Birth: {{ birthdateFormatted }}<br/>
                      Doctor Name: Dr. {{ doctor.label }}<br/>
                      Appointment Date: {{ appointment.date }} {{appointment.time}}
                    </div>
                    <div class="confirm-booking-notes">
                      Notes:<br/>
                      This appointment details will be sent to your address, please check your email for your reminder.
                    </div>
                  </div>
                </div>
                <vs-button class="mt-6 ml-auto flex" @click.prevent="confirmAppointment">Confirm</vs-button>
              </vx-card>
            </div>
          </div>
        </tab-content>
      </form-wizard>
      <div v-if="showEndOfBooking">
        <div class="vx-row">
          <div class="vx-col lg:w-3/3 w-full">
            <vx-card class="mb-base">
              <div class="confirm-booking-container">
                <div class="confirm-booking-header">
                  <div class="confirm-booking-img">
                    <img  :src="organizationDetails.custom_illustration_step_3" alt="Thank you for your booking!">
                  </div>
                  <h3>{{bookingFinished ? $t('book.successfullBooking') : $t('book.beforeBooking')}}</h3>
                </div>
                <div class="confirm-booking-content">
                  <div v-if="!bookingFinished" class="confirm-booking-notification">
                    {{$t('book.checkDetailsBeforeBooking')}}
                  </div>
                  <div class="confirm-booking-patient-info">
                    {{$t('fullName')}}: {{ getCurrentUser.first_name }} {{ getCurrentUser.last_name }}<br/>
                    {{$t('birthDate')}}: {{ birthdateFormatted }}<br/>
                    {{$t('doctorName')}}: Dr. {{ appointment.practitioner }}<br/>
                    {{$t('appointmentDate')}}: {{ appointment.date }}
                  </div>
                  <div class="confirm-booking-notes">
                    {{$t('notes')}}:<br/>
                    {{$t('book.appointmentNotes')}}
                  </div>
                </div>
              </div>
              <vs-button v-if="!bookingFinished" class="mt-6 ml-auto flex" @click.prevent="storeNewAppointment">{{$t('confirm')}}</vs-button>
              <vs-button v-else class="mt-6 ml-auto flex" @click.prevent="$router.push('/')">{{$t('goToHomePage')}}</vs-button>
            </vx-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import {FormWizard, TabContent, WizardButton} from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import Datepicker from "vuejs-datepicker";
import Button from "../../components/vuesax/button/Button";
import moduleAppointment from '@/store/appointment/moduleAppointment.js';
import moduleDoctor from '@/store/doctor/moduleDoctor.js'
import vSelect from 'vue-select';
import AppointmentSelectDate from '@/components/appointment/AppointmentSelectDate.vue';
import { ValidationProvider } from "vee-validate";
import LoginFirebase from '@/views/pages/login/LoginFirebase.vue'
import 'vue-tel-input/dist/vue-tel-input.css';
import { VueTelInput } from 'vue-tel-input';
import axios from "axios";
import {getToken} from "@/utils/storage";
import moment from "moment";


export default {
  order: 1,
  components: {
    Button,
    Datepicker,
    FormWizard,
    TabContent,
    WizardButton,
    vSelect,
    ValidationProvider,
    VueRecaptcha,
    AppointmentSelectDate,
    LoginFirebase,
    VueTelInput
  },
  data() {
    return {
      packgId: null,
      dayNo: null,
      slotId: null,
      greeting: "Mr.",
      firstName: "",
      lastName: "",
      email: "",
      gender: "male",
      mobileNum: "",
      address: "",
      postalCode: "",
      city: "",
      nationality: "",
      job: "",
      expectedDeliveryDate: new Date(),
      roomType: "",
      roomBookDate: new Date(),
      doctor: {
        label: "",
        code: ""
      },
      doctorNotSelected: null,
      identityFile: null,
      fileName: "",
      patientType: "new",
      birthdate: "",
      state: "",
      reliablePersonName: "",
      reliablePersonRelationship: "",
      reliablePersonPhoneNumber: "",
      reliablePersonGreeting: "Mr.",
      warnedPersonGreeting: "Mr.",
      warnedPersonPhoneNumber: "",
      warnedPersonName: "",
      warnedPersonLink: "",
      recaptchaVerified: false,
      recaptchaRequiredMessage: false,
      startHour: "",
      startMinute: "",
      roomTypes: [
        "Suite Privilege - 350€/day",
        "Premium - 210€/day",
        "Comfort - 115€/day",
        "Double - 100€/day",
        "Family appartment - Consult US"
      ],
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      },
      showLoginModal: false,
      isPatientExists: false,
      showEndOfBooking: false,
      form: {
        username: '',
        password: '',
      },
      appointment: {
        date: '',
        time: '',
        practitioner: null,
        serviceUnit: null,
        firstName: '',
        lastName: '',
      },
      bookDuration: null,
      bookingFinished: false,
    }
  },
  methods: {
    handleFileChange(event) {
      const target = event.target;
      const file = (target.files)[0];
      const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      toBase64(file).
      then((result) => {
        this.identityFile = result;
        this.fileName = file.name;
      }).
      catch((error) => console.log(error));
    },
    goToIdentityStep() {
      // return new Promise(() => {
      //   this.$validator.validateAll('patient-general-info').then(result => {
      //     if (result) {
      //       this.$refs.checkoutWizard.nextTab();
      //     }
      //   });
      // })
      axios.post(`${process.env.VUE_APP_GATEWAY_URL}/auth/users/user`, {
        "email": this.email,
        "firstName": this.firstName,
        "lastName": this.lastName,
        "username": this.email,
        "enabled": true,
        "gender": this.gender,
        "birthdate": moment(this.birthdate).format("YYYY-MM-DD"),
        "credentials": [
          {
            type: 'password',
            value: '123456789',
            temporary: false,
          }
        ],
        attributes: {
            "DOB": this.birthdate,
          },
          "requiredActions": [
            "UPDATE_PASSWORD",
          ]
      },
        {
          'Content-Type': 'application/json',
        }).then(() => {
        this.$store.dispatch('auth/loginUser', {username: this.email, password: '123456789'}).then(() => {
          this.$vs.loading.close()
          this.$store.dispatch('auth/getUserProfile').then(() => {
            setTimeout(() => {
              this.$store.dispatch('auth/fetchPatient')
              this.showEndOfBooking = true
            }, 200)
          })

        })
      })
    },
    goToContactPersonStep() {
      return new Promise(() => {
        this.$validator.validateAll('patient-identity-info').then(result => {
          console.log(this.$validator.errors);
          if (result) {
            this.$refs.checkoutWizard.nextTab();
          }
        });
      })
    },
    goToSummaryStep() {
      return new Promise(() => {
        if (this.recaptchaVerified === false) {
          this.recaptchaRequiredMessage = "Recaptcha is required";
        }
        this.$validator.validateAll('patient-contact-person-info').then(result => {
          console.log(this.$validator.errors);
          if (result && this.recaptchaVerified) {
            this.$refs.checkoutWizard.nextTab();
          }
        });
      })
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    verifyRecaptcha() {
      this.recaptchaVerified = true;
      this.recaptchaRequiredMessage = false;
    },
    confirmAppointment() {
      this.$vs.loading();
      const payload = {
        "address": [
          {
            "city": this.city,
            "district": "",
            "line": [
              this.address
            ],
            "period": {
              "end": "",
              "start": ""
            },
            "postalCode": this.postalCode,
            "state": this.state,
            "type": "",
            "use": ""
          }
        ],
        "dateOfBirth": this.dateOfBirth,
        "email": this.email,
        "endTime": this.$moment(this.dayNo, "DDD").set('hour', this.startHour).set('minute', this.startMinute).add(30, 'minutes').format(),
        "firstName": this.firstName,
        "fullName": this.firstName + ' ' + this.lastName,
        "gender": this.greeting === 'Mr.' ? 'm' : 'f',
        "identityNo": "",
        "intervalInSeconds": 900,
        "job": this.job,
        "lastName": this.lastName,
        "nationality": this.nationality,
        "notes": "",
        "organizationId": 1, // ???
        "packgId": this.pkgId,
        "passportExpiry": "",
        "passportNo": "",
        "patientId": 0,
        "pregnancyDueDate": this.expectedDeliveryDate,
        "relatedPerson1": {
          "identifier": "",
          "name": this.reliablePersonName,
          "relationship": this.reliablePersonRelationship,
          "telephone": this.reliablePersonPhoneNumber
        },
        "relatedPerson2": {
          "identifier": "",
          "name": this.warnedPersonName,
          "relationship": this.warnedPersonLink,
          "telephone": this.warnedPersonPhoneNumber
        },
        "resourceMobillizations": [
          {
            "activityIds": [
              "string"
            ],
            "packgIds": [
              "string"
            ],
            "resourceId": 0,
            "resourceRelativeEndingTimes": [
              "string"
            ],
            "resourceRelativeStartingTimes": [
              "string"
            ],
            "resourceType": "string",
            "serviceRequestIds": [
              "string"
            ]
          }
        ],
        "startTime": this.$moment(this.dayNo, "DDD").set('hour', this.startHour).set('minute', this.startMinute).format(),
        "status": "pending",
        "telephone": this.telephone,
        "title": "",
        "type": ""
      }
      this.$store.dispatch("moduleAppointment/bookAppointment", payload)
        .then(() => {
          this.$vs.loading.close();
          return this.$router.push('/apps/preventiveHealth/booking-success');
        })
        .catch(err => {
          console.error(err);
          this.$vs.loading.close()
        });
    },
    fetchPrationerDuration() {
      const axios = require('axios');
      let data = '';

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_GATEWAY_URL+'/api/method/frappe.client.validate_link?doctype=Appointment Type&docname=Maternity&fields=["allow_booking_for","default_duration"]',
        headers: {
          'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`,
        },
        data : data
      };

      axios.request(config)
        .then((response) => {
          this.bookDuration = response.data.message.default_duration;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    storeNewAppointment() {
      let data = {
        "docstatus": 0,
        "doctype": "Patient Appointment",
        "owner": "Administrator",
        "naming_series": "HLC-APP-.YYYY.-",
        "appointment_for": "Practitioner",
        "company": "Healminder",
        "add_video_conferencing": 0,
        "practitioner": this.appointment.practitioner,
        "appointment_time": this.appointment.time,
        "practitioner_name": "A",
        "department": "ENT",
        "duration": this.bookDuration,
        "service_unit": this.appointment.serviceUnit,
        "appointment_type": "Health Check",
        "patient_name": this.getCurrentUser.first_name,
        "inpatient_record": null,
        "patient_sex": this.getPatientData.sex,
        "patient": this.getPatientData.name,
        "patient_age": null,
        "appointment_date": this.appointment.date,
      }

      let form = new FormData;
      form.append('doc', JSON.stringify(data))
      form.append('action', 'Save')

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_GATEWAY_URL+'/api/method/frappe.desk.form.save.savedocs',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
          'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`,
        },
        data : form,
      };

      this.$vs.loading()

      axios.request(config)
        .then(() => {
          this.appointmentActive = false
          // this.fetchAvailabilities()
          this.$vs.loading.close()
          this.bookingFinished = true
        })
        .catch((error) => {
          console.log(error);
        });
    },
    login() {
      this.$vs.loading()
      this.$store.dispatch('auth/loginUser', this.form).then(() => {
        this.$vs.loading.close()
        this.$store.dispatch('auth/getUserProfile').then(() => {
          this.showEndOfBooking = true
          setTimeout(() => {
            this.$store.dispatch('auth/fetchPatient')
          }, 200)
        })

      })
    }
  },
  watch:{
    getCurrentUser: {
      handler(user){
        if (user.emailAddress) {
          this.firstName = user.firstName || "";
          this.lastName = user.lastName || "";
          this.birthdate = user.dob || "";
          this.email = user.emailAddress || "";
          this.showLoginModal = false;
        }
      },
      deep: true
    }
  },
  computed: {
    getCurrentUser() {
      return this.$store.state.auth.userProfile;
    },
    doctorsList() {
      let options = [];
      this.$store.state.moduleDoctor.doctors.map((doctor) => {
        options.push({
          'value': doctor.resourceId,
          'label': `${doctor.firstName} ${doctor.lastName}`
        });
      });
      return options;
    },
    getRecaptchaKey() {
      return process.env.VUE_APP_RECAPTCHA_KEY;
    },
    birthdateFormatted() {
      return this.$moment(this.birthdate).format("DD MMMM YYYY")
    },
    appointmentDateFormatted() {
      return this.$moment(this.dayNo, "DDD").format("dddd, DD MMMM YYYY")
    },
    getPatientData() {
      return this.$store.state.auth.patientData
    },
    validateForm () {
      return !this.errors.any() && this.form.username !== '' && this.password !== ''
    },
    organizationDetails() {
      return this.$store.state.settings.organizationDetails
    }
  },
  mounted() {
    // if (typeof this.currentUser == 'undefined') {
    //   this.showLoginModal = true;
    // }
    this.appointment = {
      date: this.$route.params.day,
      time: this.$route.params.time,
      practitioner: this.$route.params.practitioner,
      serviceUnit: this.$route.params.serviceUnit,
    }
   this.fetchPrationerDuration()
    this.$store.dispatch('auth/fetchPatient')
    if (getToken()) {
      this.showEndOfBooking = true
    }
  },
  created() {
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule('moduleDoctor', moduleDoctor)
      moduleDoctor.isRegistered = true
    }
    this.$store.dispatch("moduleDoctor/fetchDoctors").catch(err => { console.error(err) })
    if (!moduleAppointment.isRegistered) {
      this.$store.registerModule('moduleAppointment', moduleAppointment)
      moduleAppointment.isRegistered = true
    }

    this.pkgId = this.packgId = this.$route.params.pkg_id;
    this.dayNo = this.$route.params.day_no;
    this.slotId = this.$route.params.slot_id;
    this.startHour = parseInt(this.$route.params.slotHour);
    this.startMinute = parseInt(this.$route.params.slotMinute);
    this.firstName = this.getCurrentUser.firstName || "";
    this.lastName = this.getCurrentUser.lastName || "";
    this.birthdate = this.getCurrentUser.dob || "";
    this.email = this.getCurrentUser.emailAddress || "";
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
@import "@/assets/scss/variables.scss";

.existing-account-container {
  width: 60%;
}
.book-appointment-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 35px;
  .wizard-card-footer {
    padding: 0;
  }
  .wizard-progress-with-circle, .wizard-nav-pills {
    display: none;
  }
  .wizard-tab-content {
    padding: 0;
  }
  .patient-form {
    .vx-row {
      .vx-col:nth-child(odd) {
        padding: 0 20px 0 0 !important;
      }
      .vx-col:nth-child(even) {
        padding: 0 0 0 20px !important;
      }
    }
    h5, label, .vs-radio--label, .vs-input--label, .vs-checkbox--input, .con-slot-label {
      font-size: 14px;
      color: var(--labelColor);
    }
    label {
      padding: 5px 0;
    }
    .vs-con-input {
      height: 48px;
    }
    .doctor-chosen-container {
      margin: 18px 0;
    }
    /////////
    .file-input-wrapper {
      position: relative;
    }
    .inputfile {
      display: none;
    }
    .file-box {
      display: inline-block;
      width: 100%;
      border: 1px solid;
      padding: 14px 0px 32px 155px;
      box-sizing: border-box;
      height: calc(2rem - 2px);
    }
    .file-button {
      background: $lightGreen;
      font-weight: 700;
      padding: 12px 20px 10px 20px;
      position: absolute;
      border: 1px solid;
      top: 0px;
      left: 0px;
      .file-button-content {
        position: relative;
        svg {
          position: absolute;
        }
        .file-button-title {
          padding-left: 30px;
          color: $white;
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
    .person-notify {
      font-size: 14px;
      color: $darkGreen;
      .person-notify-title {
        font-weight: 700;
      }
      &.warned-person {
        margin-top: 25px;
      }
    }
    .general-conditions-title {
      color: $darkGreen;
      font-weight: 700;
      margin: 60px 0 25px 0;
    }
    .general-conditions-description {
      color: $darkGreen;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .text-danger {
      font-size: 12px;
    }
    .greeting-container {
      display: flex;
      flex-direction: row;
      select {
        color: $textColor;
        padding: 15px 6px 14px 10px !important;
        background: $white;
        border: 1px solid var(--inputField);
        border-right: none;
        font-size: 14px;
        height: 48px;
      }
    }
    .appointment-room-book-calendar {
      display: flex;
      align-items: center;
      justify-content: center;
      .calendar-wrapper {
        margin-top: 30px;
      }
    }
    .vdp-datepicker__calendar .disabled {
      color: #ddd;
      cursor: default;
    }
  }
  .confirm-booking-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .confirm-booking-img {
      max-width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    h3 {
      margin: 25px 0 70px 0;
      color: $darkGreen;
    }
  }
  .confirm-booking-content {
    .confirm-booking-notification {
      color: $darkGreen;
      margin-bottom: 30px;
    }
    .confirm-booking-patient-info {
      color: $lightGreen;
      margin-bottom: 30px;
    }
    .confirm-booking-notes {
      font-style: italic;
      color: $darkGreen;
      margin-bottom: 30px;
    }
  }
  .organization-details {
    background-color: var(--cardColor);
    text-align: center;
    padding: 30px 0;
    h1 {
      color: $white;
      font-size: 32px;
    }
  }

  @media (max-width: 980px) {
    .organization-details {
      background-color: $darkGreen;
      text-align: center;
      padding: 20px 0;

      h1 {
        font-size: 22px;
      }
    }

  }
  // move to separate component
  .appointment-progressbar-container {
    margin: 50px 0 70px 0;
    .progress-steps {
      display: flex;
      align-items: center;
      counter-reset: step;
      //justify-content: center;
      .progress-step
      {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $darkGreen;
        font-size: 16px;
        font-weight: 700;
        &:before {
          content:counter(step);
          counter-increment: step;
          width: 50px;
          height: 50px;
          display: block;
          margin: 0 auto;
          border-radius: 50%;
          line-height: 48px;
          font-size: 20px;
          background-color: $darkGreen;
          color: $white;
          text-align: center;
          font-weight: bold;
        }
        .progress-step-title {
          padding: 0 70px 0 10px;
          text-align: center;
        }
      }
      @media (max-width: 980px) {
        justify-content: space-between;
        .progress-step {
          flex-direction: column;
          font-size: 13px;
          div.progress-step-title {
            padding: 10px 0 !important;
          }
        }
      }
    }
  }
  // calendar
  .vdp-datepicker__calendar {
    padding: 5px;
    .next:after {
      border-left: 10px solid $darkGreen;
    }
    .prev:after {
      border-right: 10px solid $darkGreen;
    }
    .cell {
      font-size: 12px;
    }
    .day-header {
      color: $darkGreen !important;
    }
    .day {
      color: $textColor;
      font-size: 12px;
      &.highlighted {
        background-color: $darkGreen !important;
        color: $white;
      }
      &.selected {
        background-color: $darkGreen !important;
        color: $white;
      }
    }
  }
  .vdp-datepicker input{
    border: 1px solid var(--inputField);
    border-radius: 0;
    padding: 15px 10px !important;
  }

  // move to component
  .calendar-wrapper {
    scrollbar-width: none; /* Firefox 64 */
    -ms-overflow-style: none; /* Internet Explorer 11 */
    &::-webkit-scrollbar { /** WebKit */
      display: none;
    }
    overflow-y: scroll;
    margin: 20px 0;
    padding: 40px 30px;
    //max-width: 360px;
    width: 50%;
    border: 1px solid $darkGreen;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    //max-height: 400px;
    .calendar-header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .calendar-header-title {
        font-size: 20px;
        text-align: center;
        font-weight: 700;
        color: $darkGreen;
        padding: 0 0 8px 12px;
      }
    }
    .vdp-datepicker__calendar {
      border: none;
    }
    &.timeslot-wrapper {
      margin: 0 0 0 20px;
      .timeslot-header {
        display: flex;
        align-items: center;
        padding: 8px 0 8px;
        align-self: flex-start;
        font-size: 14px;
        font-weight: 700;
        .timeslot-count {
          font-size: 10px;
          padding-left: 8px;
          color: $lightGreen;
        }
        .day-part {
          padding-left: 8px;
        }
        .day-part-icon {
          width: 25px;
          height: 25px;
        }
      }
      .time-slot-container {
        max-height: 250px;
        overflow: auto;
        scrollbar-width: none; /* Firefox 64 */
        -ms-overflow-style: none; /* Internet Explorer 11 */
        &::-webkit-scrollbar { /** WebKit */
          display: none;
        }
        .timeslot-content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: 27px;
          .time-slot-item {
            color: $textColor;
            font-size: 12px;
            padding: 8px 14px;
            border: 1px solid #eee;
            margin: 3px 5px;
            border-radius: 6px;
            box-shadow: 2px 2px 2px #eee;
            &:hover {
              cursor: pointer;
              background-color: $darkGreen;
              color: $white;
            }
          }
        }
      }
      @media (max-width: 980px) {
        margin-top: 20px !important;
      }
    }
    @media (max-width: 980px) {
      width: 100%;
      margin: 0;
    }
  }
  @media (max-width: 575px) {
    .patient-form {
      .vx-row {
        .vx-col:nth-child(odd) {
          padding: 0 !important;
        }
        .vx-col:nth-child(even) {
          padding: 0 !important;
        }
      }
    }
  }
}
.email-link-button, .vs-divider, #registerUser {
  display: none;
}
.vs-popup--header {
  background-color: $green;
  border-radius: 5px 5px 0 0;
}
.vs-popup--close {
  border-radius: 50%;
}
.vue-tel-input {
  border: 1px solid var(--inputField) !important;
  border-radius: 0;
  padding: 6px 10px !important;
  &:focus {
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.15) !important;
  }
  &:focus-within {
    box-shadow: 0 3px 10px 0 rgba(0,0,0,.15) !important;
  }
}

@media (max-width: 980px) {
  .book-appointment-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .existing-account-container {
    width: 100%;
  }
}
</style>


